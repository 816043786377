import React from "react";
import "./styles.css"
const Privacy = () => {
  return (
    <div>
      <div >
        
        <h2>Privacy Policy</h2>
        <div>
          
          <h3>
            Introduction
          </h3>
          <p>
            This privacy and data policy (Privacy Policy) applies to Misterton tennis Club
          </p>
          <p>
            This privacy policy applies and has effect in respect of the
            Misterton tennis Club service and any related online services (together, the
            Services).
          </p>
          <p>
            If you have any questions or comments about this Privacy Policy,
            please contact us:
          </p>
          <p>Data Protection Officer</p> <p>by e-mail: admin@mistertontennis.co.uk</p>
          <p>by post: Ash Barn, Lower Severalls TA18 7NX</p>
          <p>
            We are committed to protecting and respecting your privacy. This
            Privacy Policy explains the basis on which personal information we
            collect from you will be processed by us or on our behalf. We will
            comply with all applicable data protection laws, including the
            General Data Protection Regulation 2016/679 and the Data Protection
            Act 2018.
          </p>
          <p>
            Please read this Privacy Policy carefully, as it contains important
            information about the following:
          </p>
          <ul className="p-bold">
            
            <li>The information we may collect about you;</li>
            <li>How we will use the information we collect about you;</li>
            <li>Whether we will disclose your details to anyone else;</li>
            <li>
              Your choices and rights regarding the personal information you
              have provided to us.
            </li>
            <br />
          </ul>
         
    
          <h3>
            1. Information We May Collect About You
          </h3>
          <p>
            To provide our Services to you, we collect and process the following
            information which may include your personal data. "Personal data" or
            "personal information" means any information about an individual
            from which that person can be identified. It does not include data
            where the identity has been removed (anonymous data).
          </p>
          <p>
            Information provided by you when using the Services ("Basic
            Information")
          </p>
          <p>We may collect the following information from you:</p>
          <ul>
            
            <li>Your user ID (where you create a Misterton tennis Club account);</li>
            <li>
              Contact details: name, title, addresses, telephone numbers and
              e-mail addresses;
            </li>
            <li>Date of birth;</li> <li>Gender;</li>
            <li>Password;</li> <li>Medical information.</li>
          </ul>
          <p>
            Information about you collected from third parties ("Third Party Log
            In Information")
          </p>
          <p>
            When you access the Services via a third party social media provider
            such as Facebook, Google, Microsoft or a sports governing body, we
            may collect and store personal and non-personal information which is
            available on that third party social media provider, such as your
            name, its URL, your User ID, email address and gender information.
            We may also receive technical data in order to ensure the Services
            connect to the correct third-party account.
          </p>
          
          <h3>
            2. How We Collect Your Personal Information
          </h3>
          <p>
            We may collect personal information about users of the platform in a
            number of different ways.
          </p>
          <ul>
            
            <li>
              Directly from you - for example, through the booking of a resource
              (e.g. a court or pitch), booking of a course or session (e.g.
              coaching or group run), purchasing or renewing membership or
              booking an activity at an event.
            </li>
            <li>
              From someone else acting on your behalf – for example, where a
              parent or guardian has purchased a membership or course for a
              child. On occasion, a coach may upload your personal
              information directly, i.e. when first setting up an account on
              Misterton tennis Club.
            </li>
          </ul>
          <h3>
            3. Our Basis For Processing Personal Data And The Purposes For Which
            We Use It
          </h3>
          <p>
            The main purpose of processing personal information during the
            provision of the Service is to:
          </p>
          <ul>
            <li>process court, or other resource bookings;</li>
            <li>process coaching sessions, course and programme bookings;</li>
            <li>process membership transactions;</li>
            <li>process event bookings;</li>
            <li>set up and process competition data;</li>
            <li>set up coaches or volunteers to run coaching programmes</li>
          </ul>
          <p>
            Where we decide the purpose or means for the processing of the
            personal data that you provide when using the Services, we are the
            "data controller" for the purposes of Data Protection law. We have
            provided further details on our lawful reason for processing your
            personal information below.
          </p>
          <p>
            Performance of a contract. To perform the services you have
            requested under the terms of our services agreed between us and
            other relevant agreements that you enter into from time to time.
          </p>
          <p>
            Pursuit of legitimate interests. In some cases, we may use your
            personal information to pursue legitimate interests of our own,
            including commercial interests and those with a wider public
            benefit. In essence, our principal legitimate interest is in
            pursuing our mission of enabling more people to be active through
            the use of technology. For example, we need to process personal data
            in pursuit of our legitimate interests to:
          </p>
          <ul>
            
            <li>
              understand the effectiveness of our communications to attract new users
              and re-engage our current users;
            </li>
            <li>
              conduct surveys to improve the service provided by Misterton tennis Club;
            </li>
            <li>
              ensure that our Services function properly so that you and other
              users have the best experience;
            </li>
            <li>
              research and statistical analysis (e.g. to review participation
              patterns).
            </li>
          </ul>
          <p>
            In all instances we will ensure your interests and fundamental
            rights do not override those interests.
          </p>
          <p>
            Consent. Misterton tennis Club may also process your personal data on the basis
            of consent you give, for example to send you certain direct
            marketing communications.
          </p>
          <p>
            Compliance with our legal obligations. In some cases, Misterton tennis Club
            needs to process your personal data in order to comply with its
            legal obligations. For example, we need to process personal data in
            order to comply with health and safety legislation, link to
            suppliers for Disclosure and Barring Services (criminal records) to
            obtain checks on coaches and volunteer roles (where lawful) for
            safeguarding purposes, report certain tax information about our
            financial arrangements with third parties to HM Revenue &amp;
            Customs and assist with investigations by police and/or other
            competent authorities.
          </p>
          <h3>
            4. Children
          </h3>
          <p>
            Our Services do not knowingly solicit information from or market to
            children under the age of 13 unless we have express
            parental/guardian consent. Our terms of use prohibit users aged
            under 13 years from accessing our Services except on behalf of a
            parent/guardian. In the event that we learn that we have collected
            personal information from a child under 13 years of age without this
            consent we will delete that information as quickly as possible.
          </p>
          <h3>
            5. How We May Use Special Categories Of Personal Information
          </h3>
          <p>
            "Special categories" of sensitive personal data, such as medical
            information, require higher levels of protection and further
            justification for collecting, storing and using this type of
            personal information.
          </p>
          <p>
            The provision of medical information is optional, and we only
            process this personal information where it is required by a
            venue/coach to improve your user experience of the course, session
            or programme provided.
          </p>
          <h3>
            6. Who We May Share Your Data With
          </h3>
          <p>
            Personal information collected and processed by Misterton tennis Club may be
            shared with the following recipients or categories of recipients,
            where necessary:
          </p>
          <ul>
            
            <li>
              we may disclose it to any member of our group (which means our
              subsidiaries), our personnel, suppliers or subcontractors insofar
              as it is reasonably necessary for the purposes set out in this
              Privacy Policy;
            </li>
            <li>
              if we are involved in a merger, acquisition, or sale of all or a
              portion of its assets, you will be notified via, account message
              and/or a prominent notice on our website of any change in
              ownership or uses of this information, as well as any choices you
              may have regarding this information;
            </li>
            <li>
              someone acting on your behalf, for example a parent or guardian
              who has purchased membership, coaching course or programmes for
              you as their child;
            </li>
            <li>
              competition organisers and officials involved in competitions you
              are taking part in;
            </li>
            <li>
              your club, coach, run leader for the purposes of membership,
              booking a court, pitch or other resource; coaching programme,
              session or camp;
            </li>
            <li>
              our suppliers, where they process data on our behalf. For example,
              payment providers and e-mail providers.
            </li>
            <li>
              our clients. For example, national governing bodies where we have
              a contractual commitment who will utilise the data in pursuit of
              their legitimate interests;
            </li>
            <li>
              we may disclose your information to the extent that we are
              required to do so by law (which may include to government bodies
              and law enforcement agencies); in connection with any legal
              proceedings or prospective legal proceedings, and in order to
              establish, exercise or defend our legal rights (including
              providing information to others for the purposes of fraud
              prevention).
            </li>
          </ul>
          <h3>
            7. Cookies
          </h3>
          <p>
            Information may be sent to your computer in the form of an Internet
            “cookie” to allow the Misterton tennis Club servers to monitor your
            requirements. The cookie is stored on your computer. The Misterton tennis Club
            server may request that your computer return a cookie to it. These
            return cookies do not contain any information you supplied or
            personally identifiable information about you. You may set your
            browser not to accept cookies. However, in a few cases, some of our
            website features may not function as a result.&nbsp;
          </p>
          <p>
            Such measures are necessary to allow Misterton tennis Club to measure the
            usability of the systems, which will help in its continuing
            development to ensure that we understand the requirements of our
            users. For detailed information on the cookies we use and the
            purposes for which we use them, see our
            <a href="https://docs.google.com/document/u/0/d/13XZzrVP177C_9zCUAyOrq9Vw8BfeRRCTNKzedjgedVE/edit">
              <u>Cookie Policy</u>
            </a>
            .&nbsp;
          </p>
          <h3>
            8. The Period For Which We Will Keep Your Information
          </h3>
          <p>
            We will only retain your personal information for as long as
            necessary to fulfil the purposes for which it was collected,
            including for the purposes of satisfying any legal, accounting or
            reporting requirements.
          </p>
          <p>
            Where we are the data controller, to determine the appropriate
            retention period for personal data, we consider the amount, nature
            and sensitivity of the personal data, the potential risk of harm
            from unauthorised use or disclosure of your personal data, the
            purposes for which we process your personal data and whether we can
            achieve those purposes through other means and the applicable legal
            requirements. In some circumstances, we may anonymise your personal
            information so that it can no longer be associated with you, in
            which case we may use such information without further notice to
            you.
          </p>
          <h3 >
            9. Rights Of Access, Correction, Erasure, And Restriction
          </h3>
          <p>
            You have the following rights over the way we process personal data
            relating to you. We aim to comply without undue delay. Where the
            venue/coach holds and determines the purpose and means for the
            personal data (and we do not decide these), they are the “data
            controller” and we are the “data processor”. In these instances, we
            may forward your request to the relevant controller.
          </p>
          <p>
            To make a request, please let us know by contacting our Data
            Protection Officer using the details below.
          </p>
          <ul>
            
            <li>
              Request access to your personal information (commonly known as a
              “data subject access request”). This enables you to receive a copy
              of the personal information and to check that we are lawfully
              processing it.
            </li>
            <li>
              Request correction of the personal information. This enables you
              to have any incomplete or inaccurate information we hold about you
              corrected. It is important that the personal information we hold
              about you is accurate and current. Please update your personal
              records for any changes on a timely basis.
            </li>
            <li>
              Object to the processing data about you. You can request the
              restriction, termination of processing or deletion of your
              personal data if:&nbsp;you consented to other processing the
              personal data and have withdrawn that consent;&nbsp;there is no
              longer a need to process that personal data for the reason it was
              collected;&nbsp;the personal data is being processed because it is
              in the public interest or it is in order to pursue a legitimate
              interest of Misterton tennis Club or a third party, you do not agree with that
              processing, and there is no overriding legitimate interest in the
              continued processing;&nbsp;the personal data was unlawfully
              processed;&nbsp;you need the personal data to be deleted in order
              to comply with legal obligations;&nbsp;the personal data is
              processed in relation to the offer of a service to a child.
            </li>
            <li>
              Obtain a machine readable copy of your personal data, which you
              can use with another service provider. Where it is technically
              feasible, you can ask us to send this information directly to
              another IT system provider if you prefer.
            </li>
          </ul>
          <h3>
            10. Data Security
          </h3>
          <p>
            We will take all reasonable technical and organisational precautions
            to prevent the loss, misuse or alteration of your personal
            information.
          </p>
          <p>
            We have established procedures to deal with any suspected data
            security breach. We will notify you and any applicable regulator of
            a suspected breach where we are legally required to do so.
          </p>
          <p>
            Please be aware that although we endeavour to provide reasonable
            security for the information we process and maintain, no security
            system can prevent all potential security breaches.
          </p>
          <h3>
            11. Transferring Of Data Internationally
          </h3>
          <p>
            Our information is generally stored within the EEA on secure
            databases.
          </p>
          <p>
            Where we transfer your information outside of the EEA, normally
            because our clients are based outside of the EEA (for example,
            because your personal data is stored on an IT system hosted outside
            of the EEA), we ensure that any such transfer meets our legal
            requirements. You can obtain more details of the protection given to
            your personal data when it is transferred outside the EEA by
            contacting us using the details below
          </p>
          <h3>
            12. Contacts
          </h3>
          <p>
            We have appointed a Data Protection Officer to oversee compliance
            with this privacy notice. If you have any questions about this
            privacy notice or how we handle your personal information, please
            contact the Data Protection Officer:
          </p>
          <p>Misterton tennis Club Group Ltd</p> <p>by e-mail: admin@mistertontennis.com</p>
          <p>by post:  Ash Barn, Lower Severalls TA18 7NX</p>
          <h3>
            13. Make A Complaint To A Supervisory Authority
          </h3>
          <p>
            If you are unhappy with how we process your personal data, please
            let us know by contacting us.
          </p>
          <p>
            If you do not agree with the way we have processed your data or
            responded to your concerns, an alternative is to submit a complaint
            to a Data Protection Supervisory Authority:
            <a href="https://ico.org.uk/">
              <u>www.ico.org.uk</u>
            </a>
          </p>
          <h3>
            14. Changes To This Privacy Notice
          </h3>
          <p>
            We reserve the right to update this privacy notice at any time, and
            we will provide you with a new privacy notice when we make any
            substantial updates. We may also notify you in other ways from time
            to time about the processing of your personal information.
          </p>
          <p>
            If you have any questions about data protection or this privacy
            notice, please contact us.
          </p>
          <p>Updated February 2024</p>
        </div>
      </div>
    </div>
  );
};
export default Privacy;
