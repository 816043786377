import React from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import keys from "../../config/keys"

const MTCMap = () => {
  const myLocation = { lat: 50.872147771425354, lng: -2.7715849073439505 };
  //const marker = new AdvancedMarker({zoom:4, position: myLocation, title: "Misterton Tennis Club"})
  return (
    <div>
      <APIProvider apiKey={keys.googlemapAPI}>
        <Map
          className="contact-map"
          defaultCenter={myLocation}
          defaultZoom={16}
          gestureHandling={"greedy"}
        >
          <Marker position={myLocation} title={"Misterton Tennis Club"} />
        </Map>
      </APIProvider>
    </div>
  );
};
export default MTCMap;
