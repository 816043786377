import "./styles.css";
import Logo from "../../images/LOGO.png";

export const NavBar = () => {

  const isAuth = sessionStorage.getItem("profile")!==null

  const toggleMenu = (e) => {
    const menu = document.getElementById("sub-menu");
    menu.className === "sub-menu"
      ? (menu.className += "  hidden")
      : (menu.className = "sub-menu");
  };

  return (
    <div className="topnav" id="myTopnav">
      <img className="logo" src={Logo} alt="logo" />
      <div  className="burger fa-solid fa-bars" onClick={toggleMenu}></div>
      <div id="sub-menu" className="sub-menu hidden">
        <a href="/">
          <i title="Home" className="fa-solid fa-house"></i>
        </a>
        <a href="/contact">{"Directions/ Contact"}</a>
        <a href="/playing">{"Playing"}</a>
        <a href="/membership">{"Membership"}</a>
        <a href="/coaching">{"Coaching"}</a>
        <a href="/events">{"Events"}</a>
        <a href="/safeguarding">{"Safeguarding"}</a>
        {/* <a
        href="auth"
          title={isAuth ? "Log Out" : "Log In"}
          className={
            isAuth
              ? "log-in-out fa-solid fa-right-from-bracket"
              : " log-in-out fa-solid fa-right-to-bracket"
          }
        ></a> */}
      </div>    
    </div>
  );
};

export default NavBar;
