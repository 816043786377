import React from 'react'
import "./MTCMap"
import "./styles.css"
import MTCMap from './MTCMap'
const Contact = () => {
return<div className="page-contact">
    <h1 className="head">Find us or Get in Touch</h1>
    <br />

<MTCMap/>
<h2 className='contact-strap'>Directions</h2>
<p className='contact-text'>
    Our Adrress is: Unity Lane, Misterton, TA18 8NA.
    <br /> <br />
    Parking is next to Misterton Village Hall, at the end of Unity Lane. Our courts are across the recreation ground.
    <br />
    If you're coming from the South on the A356 (Beaminster & South Perrott), Unity Lane is on the right, just after Mallabones cafe.
    <br />
    If you're coming from Crewkerne, it is on the left just past Misterton garage.
</p>
<h2 className='contact-strap'>
    Get in touch
</h2>
<p className="contact-text">Contact the club secretary on 07702 242000</p>
        <a className='contact-email' href="mailto:admin@mistertontennis.co.uk">
           email: admin@mistertontennis.co.uk
          </a>
          <br /><br />
</div>
}
export default Contact