import React from "react";
import mainImage from "../../images/MTC_safeguarding.jpg";
const Safeguarding = () => {
  return (
    <div className="page">
      <h1 className="head">Safeguarding at Misterton Tennis Club</h1>
      <img className="main-img" src={mainImage} alt="mainImage" />

      <div className="text-content">
        <p>
          We are committed to creating a safe and supportive environment for all
          our members. You can read our <strong>safeguarding policy statement</strong>{" "}
          <a href="documents/MTC_SPS.pdf" target="blank">
            here.
          </a>
        </p>
        <br />
        <p>
          All members of Misterton Tennis Club are required to abide by our
          <strong> codes of conduct</strong> which you can find{" "}
          <a href="documents/MTC_CoC.pdf" target="blank">
            here.
          </a>
        </p>
        <br />
        <p>
          We have adopted the <strong>Somerset LTA safeguarding policy </strong>for children and young adults, and you can find a full copy of the latest version {" "}
          <a href="documents/SLTA_S.pdf" target="blank">
            here.
          </a>
        </p>
      </div>
    </div>
  );
};
export default Safeguarding;
