import React from "react";
import mainImage from "../../images/lights_2.jpg";
import "./styles.css";
function playing() {
  return (
    <div className="page-playing">
      <h1 className="head">Playing at Misterton Tennis Club</h1>
      <img className="main-img" src={mainImage} alt="mainImage" />
      <div className="text-content">
        <h2 className="strap">Social Tennis</h2>
        We run friendly social sessions for members every week at the following
        times:
        <br />
        <table className="table-main">
          <tbody>
            <tr className="table-row">
              <td className="table-event">Sunday Morning 10.00 to midday:  All Welcome</td>
            </tr>
            <tr className="table-row">
              <td className="table-event">Monday Afternoon 2.00pm to 4.00pm: Over 50's </td>
            </tr>
            <tr className="table-row">
              <td className="table-event">Monday Evening from 6.30pm: All Welcome</td>
            </tr>
            <tr className="table-row">
              <td className="table-event">
                Thursday Evening from 6.00.pm: Men's Session
              </td>
            </tr>
            <tr className="table-row">
              <td className="table-event">
                Friday Morning from 10.00am to midday: Ladies' Session 
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <p>
          The starting times given are approximate and the sessions are
          dependent on the weather, player availability and team fixtures. There
          are WhatsApp groups for each session that members can join to keep
          updated.
        </p>
        <br />
        <h2 className="strap">Members</h2>
        <p>
          Members can reserve courts for private play up to two weeks in
          advance, using our <a href="https://bookmycourts.com/" target="blank" title="Book My Courts"> online booking system</a>. You will need to create an
          account before using the system.
        </p>
        <br />
        <h2 className="strap">Non-members</h2>
        <p>
          Non-members are welcome to play when courts are available. You can get
          details of availability and charges by calling Tim Bradley on 07702
          242000. If you are not a member and are thinking about joining the
          club, you are welcome to come along to a social session or two to 'try
          before you buy'
        </p>
        <br />
      </div>
      <br />
    </div>
  );
}

export default playing;
