
let Keys

if (process.env.NODE_ENV === 'production') {
    Keys = await import ('./keys_prod.js')
  }
  else Keys = await import ('./keys_dev.js') 

export default Keys.default

